import Vue from 'vue'
import VueRouter from 'vue-router'
import Body from '../components/Body.vue'
import dashboard from '../pages/dashboard.vue'
import category from '../pages/physical/category'
import AddProduct from '../pages/physical/AddProduct'
import SubCategory from '../pages/physical/SubCategory.vue'
import ProductList from '../pages/physical/ProductList.vue'
import ProductDetail from '../pages/digital/ProductDetail.vue'
import CategoryDigital from '../pages/digital/Category.vue'
import SubCategoryDigital from '../pages/digital/SubCategory.vue'
import SubType from '../pages/digital/SubType.vue'
import ProductMaterial from '../pages/digital/Material.vue'
import ProductListDigital from '../pages/digital/ProductList.vue'
import AddProductDigital from '../pages/digital/AddProduct.vue'
import EditProductDigital from '../pages/digital/EditProduct.vue'
import Order from '../pages/sales/order.vue'
import transactions from '../pages/sales/Transaction.vue'
import CouponList from '../pages/coupon/CouponList.vue'
import AddCoupon from '../pages/coupon/AddCoupon.vue'
import PageList from '../pages/page/PageList.vue'
import CreatePage from '../pages/page/create-page.vue'
import Media from '../pages/media.vue'
import MenuList from '../pages/menus/menu-list.vue'
import CreateMenu from '../pages/menus/create-menu.vue'
import UserList from '../pages/users/user-list.vue'
import CreateUser from '../pages/users/create-user.vue'
import VendorList from '../pages/vendors/vendor-list.vue'
import CreateVendor from '../pages/vendors/create-vendor.vue'
import LocalTranslation from '../pages/localization/translations.vue'
import CurrencyRates from '../pages/localization/currency-rates.vue'
import Taxes from '../pages/localization/taxes.vue'
import Reports from '../pages/reports.vue'
import Profile from '../pages/profiles/profile.vue'
import Invoice from '../pages/invoice.vue'
import Auth from '../pages/authentication/index.vue'
import firebase from 'firebase/app'
import Userauth from '../auth/index.js'
import FeaturedSlides from '../pages/homepage/FeaturedSlides.vue'
import vendorSummary from '../pages/vendors/vendorSummary.vue'

Vue.use(VueRouter)

const routes = [
  { path: '', redirect: { name: 'default' } },
  {
    path: '/dashboard',
    component: Body,
    children: [
      {
        path: 'default',
        name: 'default',
        component: dashboard,
        meta: {
          isAdmin: true,
          title: 'Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/physical',
    component: Body,
    children: [
      {
        path: 'category',
        name: 'category',
        component: category,
        meta: {
          isAdmin: true,
          title: 'Category | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'add-product',
        name: 'add-product',
        component: AddProduct,
        meta: {
          isAdmin: true,
          title: 'Product | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'sub-category',
        name: 'sub-category',
        component: SubCategory,
        meta: {
          isAdmin: true,
          title: 'Sub Category | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'product-list',
        name: 'product-list',
        component: ProductList,
        meta: {
          isAdmin: true,
          title: 'Product List | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/digital',
    component: Body,
    children: [
      {
        path: 'category',
        name: 'Category',
        component: CategoryDigital,
        meta: {
          isAdmin: true,
          title: 'Category | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'sub-category',
        name: 'sub-category',
        component: SubCategoryDigital,
        meta: {
          isAdmin: true,
          title: 'Category | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'sub-type',
        name: 'sub-type',
        component: SubType,
        meta: {
          isAdmin: true,
          title: 'Sub Type | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'material',
        name: 'material',
        component: ProductMaterial,
        meta: {
          isAdmin: true,
          title: 'Material | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'product-list',
        name: 'product-list',
        component: ProductListDigital,
        meta: {
          isAdmin: true,
          title: 'Product List | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'add-product',
        name: 'add-product',
        component: AddProductDigital,
        meta: {
          isAdmin: true,
          title: 'Add product | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'edit-product/:id',
        name: 'edit-product',
        component: EditProductDigital,
        meta: {
          isAdmin: true,
          title: 'Edit product | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'product-detail/:id',
        name: 'product-detail',
        component: ProductDetail,
        meta: {
          isAdmin: true,
          title: 'Product Detail | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/sales',
    component: Body,
    children: [
      {
        path: 'order',
        name: 'order',
        component: Order,
        meta: {
          isAdmin: true,
          title: 'Order | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: transactions,
        meta: {
          isAdmin: true,
          title: 'Transactions | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/coupons',
    component: Body,
    children: [
      {
        path: 'coupon-list',
        name: 'coupon-list',
        component: CouponList,
        meta: {
          isAdmin: true,
          title: 'Coupon | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'add-coupon',
        name: 'add-coupon',
        component: AddCoupon,
        meta: {
          isAdmin: true,
          title: 'Coupon | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/pages',
    component: Body,
    children: [
      {
        path: 'page-list',
        name: 'page-list',
        component: PageList,
        meta: {
          isAdmin: true,
          title: 'Pages | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'create-page',
        name: 'create-page',
        component: CreatePage,
        meta: {
          isAdmin: true,
          title: 'Pages | Multikart - Premium Admin Template'
        }
      }
    ]
  },

  {
    path: '/menus',
    name: 'menus',
    component: Body,
    children: [
      {
        path: 'menu-list',
        name: 'menu-list',
        component: MenuList,
        meta: {
          isAdmin: true,
          title: 'Menus | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'create-menu',
        name: 'create-menu',
        component: CreateMenu,
        meta: {
          isAdmin: true,
          title: 'Menus | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/users',
    name: 'users',
    component: Body,
    children: [
      {
        path: 'user-list',
        name: 'user-list',
        component: UserList,
        meta: {
          isAdmin: true,
          title: 'User | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'create-user',
        name: 'create-user',
        component: CreateUser,
        meta: {
          isAdmin: true,
          title: 'User | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: Body,
    children: [
      {
        path: 'vendor-list',
        name: 'vendor-list',
        component: VendorList,
        meta: {
          isAdmin: true,
          title: 'Vendor | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'create-vendor',
        name: 'create-vendor',
        component: CreateVendor,
        meta: {
          isAdmin: true,
          title: 'Vendor | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/vendors/summary',
    name: 'summary',
    component: vendorSummary,
    meta: { isAdmin: false }
  },
  {
    path: '/homepage',
    name: 'homepage',
    component: Body,
    children: [
      {
        path: 'featured-slides',
        name: 'featured-slides',
        component: FeaturedSlides,
        meta: {
          isAdmin: true,
          title: 'Featured Slides | Multikart - Premium Admin Template'
        }
      }
    ]
  },
  {
    path: '/localization',
    name: 'vendolocalizationrs',
    component: Body,
    children: [
      {
        path: 'translations',
        name: 'translations',
        component: LocalTranslation,
        meta: {
          isAdmin: true,
          title: 'Localization | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'currency-rates',
        name: 'currency-rates',
        component: CurrencyRates,
        meta: {
          isAdmin: true,
          title: 'Localization | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'taxes',
        name: 'taxes',
        component: Taxes,
        meta: {
          isAdmin: true,
          title: 'Localization | Multikart - Premium Admin Template'
        }
      }
    ]
  },

  {
    path: '/settings',
    name: 'settings',
    component: Body,
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          isAdmin: true,
          title: 'Profile | Multikart - Premium Admin Template'
        }
      }
    ]
  },

  {
    path: '/auth/login',
    name: 'login',
    component: Auth,
    meta: { isAdmin: true, title: 'Login | Multikart - Premium Admin Template' }
  },
  {
    path: '/app',
    component: Body,
    children: [
      {
        path: 'media',
        name: 'media',
        component: Media,
        meta: {
          isAdmin: true,
          title: 'Media | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        meta: {
          isAdmin: true,
          title: 'Reports | Multikart - Premium Admin Template'
        }
      },
      {
        path: 'invoice',
        name: 'invoice',
        component: Invoice,
        meta: {
          isAdmin: true,
          title: 'Invoice | Multikart - Premium Admin Template'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const path = ['/auth/login', '/register']
  if (path.includes(to.path) || !to.meta?.isAdmin) {
    next()
  } else {
    await Userauth.checkAuthentication(to, next)
  }
})
export default router
