<template>
  <div class="global-toast-container">
    <b-toast
      ref="globalToast"
      toaster="b-toaster-top-center"
      :title="toast?.title"
      :variant="toast?.variant"
      :no-auto-hide="toast?.noAutoHide"
      auto-hide-delay="3000"
      @hidden="onToastHidden"
    >
      {{ toast?.message }}
    </b-toast>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GlobalToast',
  computed: {
    ...mapGetters({
      toast: 'toast/getToast'
    })
  },
  watch: {
    toast(newValue) {
      if (newValue) {
        this.$refs.globalToast.show()
      }
    }
  },
  methods: {
    ...mapActions('toast', ['setToast']),
    onToastHidden() {
      this.setToast(null)
    }
  }
}
</script>

<style></style>
