const state = {
  toast: null
}

const mutations = {
  SET_TOAST(state, toast) {
    state.toast = toast
  }
}

const actions = {
  setToast({ commit }, toast) {
    console.log('toast in store', toast)
    commit('SET_TOAST', toast)
  }
}

const getters = {
  getToast: (state) => {
    return state.toast
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
