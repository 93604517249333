import api from '../../boot/api'
const url = 'user'
import { showToast } from '../../utilities/utilities'

const state = {
  userList: []
}
const getters = {
  getUsers(state) {
    return state.userList
  }
}
const actions = {
  getUsers(context, query) {
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getUsers', {
          data: response.data.results,
          totalRows: response.data.total
        })
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  createUser(context, payload) {
    return api()
      .post(url, payload)
      .then(
        (response) => {
          showToast(context, 'User Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateUser(context, { id, created_at, updated_at, ...payload }) {
    return api()
      .put(url + `/${id}`, payload)
      .then(
        (response) => {
          showToast(context, 'User Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteUser(context, id) {
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'User Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  sendInfo(context, id) {
    return api()
      .put(url + `/sendInfo/${id}`)
      .then(
        (response) => {
          showToast(context, 'Vendor Info sent Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  }
}
const mutations = {
  getUsers(state, payload) {
    state.userList = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
