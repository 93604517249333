export function showToast(context, message, variant = 'success') {
  context.dispatch(
    'toast/setToast',
    {
      title: variant === 'success' ? 'Success' : 'Error',
      message: message,
      variant: variant
    },
    { root: true }
  )
}
