import api from '../../boot/api'
const url = 'vendor'
import { showToast } from '../../utilities/utilities'

const state = {
  vendorList: [],
  vendorSummary: []
}
const getters = {
  getVendors(state) {
    return state.vendorList
  },
  getVendorSummary(state) {
    return state.vendorSummary
  }
}
const actions = {
  getVendors(context, query) {
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getVendors', {
          data: response.data.results,
          totalRows: response.data.total
        })
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  createVendor(context, payload) {
    return api()
      .post(url, payload)
      .then(
        (response) => {
          showToast(context, 'Vendor Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateVendor(context, { id, created_at, updated_at, ...payload }) {
    return api()
      .put(url + `/${id}`, payload)
      .then(
        (response) => {
          showToast(context, 'Vendor Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteVendor(context, id) {
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Vendor Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  getVendorSummary(context, query) {
    const url = '/vendor/products'
    return api()
      .get(url, { params: query })
      .then(
        (response) => {
          context.commit('getVendorSummary', {
            data: response.data.results,
            totalRows: response.data.total
          })
        },
        (err) => {
          console.log('error', err)
        }
      )
  }
}
const mutations = {
  getVendors(state, payload) {
    state.vendorList = payload
  },
  getVendorSummary(state, payload) {
    state.vendorSummary = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
