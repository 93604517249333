import api from '../../boot/api'
const url = 'product'
import { showToast } from '../../utilities/utilities'

const state = {
  products: [],
  subCategoryProduct: [],
  productList: [],
  categoryProduct: [],
  loadingStatus: false,
  subTypeProduct: [],
  singleProduct: {},
  materials: []
}
const getters = {
  getProducts: (state) => {
    return state.products
  },
  getCategoryProduct: (state) => {
    return state.categoryProduct
  },
  getSubCategoryProduct: (state) => {
    return state.subCategoryProduct
  },
  getSubTypeProduct: (state) => {
    return state.subTypeProduct
  },
  getProductList: (state) => {
    return state.productList
  },
  getProductById: (state) => {
    return state.singleProduct
  },
  loadingStatus: (state) => {
    return state.loadingStatus
  },
  getMaterial: (state) => {
    return state.materials
  }
}
const actions = {
  getProducts: (context, query) => {
    context.commit('loadingStatus', true)
    const url = 'product'
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getProducts', {
          data: response.data.results,
          totalRows: response.data.total
        })
        context.commit('loadingStatus', true)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  getProductById: (context, id) => {
    context.commit('loadingStatus', true)
    const url = 'product'
    api()
      .get(url + `/${id}`)
      .then((response) => {
        context.commit('getProductById', response.data.product)
        context.commit('loadingStatus', true)
        return response.data.product
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  getSubCategoryProduct: (context, query) => {
    context.commit('loadingStatus', true)
    const url = 'sub-category'
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getSubCategoryProduct', {
          data: response.data.results,
          totalRows: response.data.total
        })
        context.commit('loadingStatus', true)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  getSubTypeProduct: (context, query) => {
    context.commit('loadingStatus', true)
    const url = 'sub-type'
    api()
      .get(url, {
        params: query
      })
      .then((response) => {
        context.commit('getSubTypeProduct', {
          data: response.data.results,
          totalRows: response.data.total
        })
        context.commit('loadingStatus', true)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  getCategoryProduct: (context, query) => {
    context.commit('loadingStatus', true)
    const url = 'category'
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getCategoryProduct', {
          data: response.data.results,
          totalRows: response.data.total
        })
        context.commit('loadingStatus', true)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  getProductList: (context) => {
    const url = 'product'
    api()
      .get(url)
      .then((response) => {
        context.commit('getProductList', response.data)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  createProduct(context, payload) {
    const url = 'product'

    return api()
      .post(url, payload)
      .then(
        (response) => {
          showToast(context, 'Product Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteProduct(context, id) {
    const url = 'product'

    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Product Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateProduct(context, { id, ...payload }) {
    const url = 'product'
    return api()
      .put(url + `/${id}`, payload)
      .then(
        (response) => {
          showToast(context, 'Product Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  createProductMedia(context, payload) {
    const url = 'product-media'
    return api()
      .post(url, payload)
      .then(
        (response) => {
          showToast(context, 'Media Uploaded Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteProductMedia(context, id) {
    const url = 'product-media'
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Media Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateProductMedia(context, { id, ...payload }) {
    const url = 'product-media'
    return api()
      .put(url + `/${id}`, payload)
      .then(
        (response) => {
          showToast(context, 'Image Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  createCategory(context, payload) {
    const url = 'category'

    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('name', payload.name)

    return api()
      .post(url, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Category Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateCategory(context, payload) {
    const url = 'category'
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('name', payload.name)
    return api()
      .put(url + `/${payload.id}`, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Category Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteCategory(context, id) {
    const url = 'category'
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Category Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  createSubCategory(context, payload) {
    const url = 'sub-category'
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('name', payload.name)
    bodyFormData.append('category_id', payload.category_id)
    return api()
      .post(url, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Sub Category Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateSubCategory(context, payload) {
    const url = 'sub-category'
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('name', payload.name)
    bodyFormData.append('category_id', payload.category_id)
    return api()
      .put(url + `/${payload.id}`, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Sub Category Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteSubCategory(context, id) {
    const url = 'sub-category'
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Sub Category Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  createSubType(context, payload) {
    const url = 'sub-type'
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('name', payload.name)
    bodyFormData.append('category_id', payload.category_id)
    bodyFormData.append('sub_category_id', payload.sub_category_id)
    return api()
      .post(url, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Sub Type Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateSubType(context, payload) {
    const url = 'sub-type'
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('name', payload.name)
    bodyFormData.append('category_id', payload.category_id)
    bodyFormData.append('sub_category_id', payload.sub_category_id)
    return api()
      .put(url + `/${payload.id}`, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Sub Type Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteSubType(context, id) {
    const url = 'sub-type'
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Sub Type Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  getMaterial: (context, query) => {
    const url = 'material'
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getMaterial', {
          data: response.data.results,
          totalRows: response.data.total
        })
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  createMaterial(context, payload) {
    const url = 'material'
    return api()
      .post(url, payload)
      .then(
        (response) => {
          showToast(context, 'Material Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateMaterial(context, { id, ...payload }) {
    const url = 'material'
    return api()
      .put(url + `/${id}`, payload)
      .then(
        (response) => {
          showToast(context, 'Material Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteMaterial(context, id) {
    const url = 'material'
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Material Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  }
}
const mutations = {
  loadingStatus: (state, payload) => {
    state.loadingStatus = payload
  },
  getProducts: (state, payload) => {
    state.products = payload
  },
  getCategoryProduct: (state, payload) => {
    state.categoryProduct = payload
  },
  getSubCategoryProduct: (state, payload) => {
    state.subCategoryProduct = payload
  },
  getSubTypeProduct: (state, payload) => {
    state.subTypeProduct = payload
  },
  getProductById: (state, payload) => {
    state.singleProduct = payload
  },
  getProductList: (state, payload) => {
    state.productList = payload.data
  },
  changeCurrency: (state, payload) => {
    state.currency = payload
  },
  getMaterial: (state, payload) => {
    state.materials = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
