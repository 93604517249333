<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Edit Product" />
      </div>
      <div class="row product-adding col-xl-12">
        <ul class="nav nav-tabs tab-coupon" id="top-tab" role="tablist">
          <b-tabs content-class="mt-3" @input="updateActiveTab">
            <b-tab
              title="Info"
              data-feather="product"
              active
              style="display: flex"
            >
              <GeneralInfo :isEdit="true" :product="product" />
            </b-tab>
            <b-tab title="Images" data-feather="product" style="display: flex">
              <uploadMedia
                v-if="activeTab === 2"
                :images="images"
                type="images"
                @deleteImage="deleteImage"
                :id="itemId"
              />
            </b-tab>
            <b-tab
              title="Link Images"
              data-feather="product"
              style="display: flex"
            >
              <LinkImage v-if="activeTab === 3" :id="itemId" :isEdit="true" />
            </b-tab>
            <b-tab
              title="360 Image"
              data-feather="product"
              style="display: flex"
            >
              <UploadMedia
                v-if="activeTab === 4"
                type="360"
                :isEdit="true"
                :id="itemId"
              />
            </b-tab>
            <b-tab title="Asset" data-feather="product" style="display: flex">
              <UploadMedia
                v-if="activeTab === 5"
                type="asset"
                :id="itemId"
                :asset="asset"
              />
            </b-tab>
          </b-tabs>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralInfo from '../../components/addproduct/general-info.vue'
import UploadMedia from '../../components/addproduct/upload-media.vue'
import LinkImage from '../../components/addproduct/link-images.vue'
export default {
  components: {
    GeneralInfo,
    UploadMedia,
    LinkImage
  },
  data() {
    return {
      activeTab: 1
    }
  },
  created() {
    this.$store.dispatch('product/getProductById', this.itemId)
  },
  computed: {
    product: function () {
      return this.$store.getters['product/getProductById']
    },
    itemId() {
      return this.$route.params.id
    },
    images() {
      return this.product?.mediaInfo?.filter((item) => item.type === 'image')
    },
    asset() {
      return this.product?.mediaInfo
        ?.filter((item) => item.type === 'asset')
        .pop()
    }
  },
  methods: {
    async deleteImage(imageId) {
      const result = await this.$store.dispatch(
        'product/deleteProductMedia',
        imageId
      )
      if (result.status === 200)
        this.$store.dispatch('product/getProductById', this.itemId)
    },
    updateActiveTab(tabIndex) {
      this.activeTab = tabIndex + 1
    }
  }
}
</script>
<style scoped>
.ck-content {
  height: 500px;
}
</style>
