import axios from 'axios'
import config from '../../config.json'
export default () => {
  var api = axios.create({
    baseURL: config.baseUrl,
    headers: {
      Authorization: localStorage.getItem('token')
    }
  })

  return api
}
