<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Material" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Product Material</h5>
            </div>
            <div class="card-body">
              <div class="btn-popup pull-right">
                <b-button
                  @click="
                    showModal = true
                    selectedItem = {}
                  "
                  variant="primary"
                  >Add Material
                </b-button>
              </div>
              <GlobalMaterialModal
                @formData="formData"
                @onCancel="onCancel"
                @onEdit="onEdit"
                :showModal="showModal"
                :selectedItem="selectedItem"
                :isEdit="isEdit"
                type="category"
              />
              <div class="table-responsive datatable-vue product-physical">
                <b-modal id="modal-2" title="Confirmation" @ok="deleteItem">
                  <p class="my-4">Are you sure!</p>
                </b-modal>
                <b-table
                  :select-mode="selectMode"
                  class="text-center"
                  striped
                  head-variant="light"
                  bordered
                  show-empty
                  stacked="md"
                  :items="items?.data"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #cell(actions)="{ item }">
                    <feather
                      type="edit-2"
                      stroke="#3758FD"
                      stroke-width="1"
                      size="18px"
                      fill="#3758FD"
                      stroke-linejoin="round"
                      @click="
                        showModal = true
                        selectedItem = item
                        isEdit = true
                      "
                    >
                    </feather>
                    <feather
                      type="trash"
                      v-b-modal.modal-2
                      @click="selectedItem = item"
                      stroke="#F72E9F"
                      size="18px"
                      fill="#F72E9F"
                    >
                    </feather>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="items?.totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GlobalMaterialModal from '../../components/modals/globalMaterialsModal.vue'
import statusIcon from '../../components/featherIcons/status-icon.vue'

export default {
  components: { statusIcon, GlobalMaterialModal },
  data() {
    return {
      tablefields: [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'actions' }
      ],
      selectMode: 'single',
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: '',
      selectedSku: '',
      index: null,
      showModal: false,
      isEdit: false,
      selectedItem: {}
    }
  },
  created() {
    this.$store.dispatch('product/getMaterial', {
      page: this.currentPage,
      limit: this.perPage
    })
  },
  computed: {
    ...mapGetters({
      items: 'product/getMaterial'
    })
  },
  watch: {
    currentPage(newPage) {
      this.$store.dispatch('product/getMaterial', {
        page: newPage,
        limit: this.perPage
      })
    }
  },
  methods: {
    onCancel() {
      this.showModal = false
      this.isEdit = false
    },
    async formData(categoryData) {
      const result = await this.$store.dispatch(
        'product/createMaterial',
        categoryData
      )
      if (result.status === 200) {
        this.showModal = false
        this.$store.dispatch('product/getMaterial', {
          page: this.currentPage,
          limit: this.perPage
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onEdit(categoryData) {
      const result = await this.$store.dispatch(
        'product/updateMaterial',
        categoryData
      )
      if (result.status === 200) {
        this.showModal = false
        this.isEdit = false
        this.$store.dispatch('product/getMaterial', {
          page: this.currentPage,
          limit: this.perPage
        })
      }
    },
    async deleteItem() {
      const result = await this.$store.dispatch(
        'product/deleteMaterial',
        this.selectedItem.id
      )
      this.selectedItem = {}
      if (result.status === 200)
        this.$store.dispatch('product/getMaterial', {
          page: this.currentPage,
          limit: this.perPage
        })
    }
  }
}
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
