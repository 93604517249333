<template>
  <b-modal
    v-model="showModal"
    id="modal-1"
    :title="title"
    @ok="onEdit($event)"
    @cancel="onCancel($event)"
    ok-title="Save"
    ok-variant="primary"
  >
    <ValidationObserver ref="form">
      <form class="needs-validation">
        <div class="form">
          <ValidationProvider
            v-if="selectedItem.name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label for="validationCustom01" class="mb-1"
                >* Vendor Name:</label
              >
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Vendor Name"
                v-model="userData.name"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* First Name:</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="First Name"
                v-model="userData.first_name"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Last Name:</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Last Name"
                v-model="userData.last_name"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Email:</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Email"
                v-model="userData.email"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-if="type === 'vendor'"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Address:</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Address"
                v-model="userData.address"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1"
                >* Phone Number:</label
              >
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                pattern="[0-9]{11}"
                name="Phone Number"
                v-model="userData.phone_number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-if="type === 'vendor'"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Level:</label>
              <select
                class="custom-select"
                required=""
                v-model="userData.level"
              >
                <option value="">--Select--</option>
                <option
                  v-for="option in levelsData"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.value }}
                </option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  name: 'GlobalUserModal',
  props: ['type', 'showModal', 'title', 'selectedItem'],
  data() {
    return {
      userData: {},
      levelsData: [
        { id: 1, value: '$' },
        { id: 2, value: '$$' },
        { id: 3, value: '$$$' },
        { id: 4, value: '$$$$' },
        { id: 5, value: '$$$$$' }
      ]
    }
  },
  watch: {
    selectedItem(newValue) {
      if (newValue?.userInfo) {
        const { userInfo, ...rest } = newValue
        const updatedData = {
          ...rest,
          ...userInfo
        }
        this.userData = { ...updatedData }
      } else {
        this.userData = { ...newValue }
      }
    }
  },
  methods: {
    resetUserData() {
      if (this.selectedItem?.userInfo) {
        const { userInfo, ...rest } = this.selectedItem
        const updatedData = {
          ...rest,
          ...userInfo
        }
        this.userData = { ...updatedData }
      } else {
        this.userData = { ...this.selectedItem }
      }
    },
    onCancel(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetUserData()
      this.$emit('onCancel')
    },
    onEdit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          this.$emit('onEdit', this.userData)
        }
      })
    }
  }
}
</script>
