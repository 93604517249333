<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Users" title="Create User" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5>Add User</h5>
            </div>
            <div class="card-body">
              <ul class="nav nav-tabs tab-coupon" id="top-tab" role="tablist">
                <div class="tab-content" id="top-tabContent">
                  <b-tabs content-class="mt-3">
                    <b-tab title="Account" data-feather="user" active>
                      <ValidationObserver ref="form">
                        <form>
                          <h4>Account Details</h4>
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="form-group row">
                              <label
                                for="validationCustom0"
                                class="col-xl-3 col-md-4"
                                ><span>*</span> First Name</label
                              >
                              <div class="col-xl-8 col-md-7">
                                <input
                                  class="form-control"
                                  id="validationCustom0"
                                  type="text"
                                  v-model="firstName"
                                  name="First Name"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="form-group row">
                              <label
                                for="validationCustom0"
                                class="col-xl-3 col-md-4"
                                ><span>*</span> Last Name</label
                              >
                              <div class="col-xl-8 col-md-7">
                                <input
                                  class="form-control"
                                  id="validationCustom0"
                                  type="text"
                                  v-model="lastName"
                                  name="Last Name"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <div class="form-group row">
                              <label
                                for="validationCustom0"
                                class="col-xl-3 col-md-4"
                                ><span>*</span> Email</label
                              >
                              <div class="col-xl-8 col-md-7">
                                <input
                                  class="form-control"
                                  id="validationCustom0"
                                  type="text"
                                  v-model="email"
                                  name="Email"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            rules="required|phone"
                            v-slot="{ errors }"
                          >
                            <div class="form-group row">
                              <label
                                for="validationCustom0"
                                class="col-xl-3 col-md-4"
                                ><span>*</span> Phone Number</label
                              >
                              <div class="col-xl-8 col-md-7">
                                <input
                                  class="form-control"
                                  id="validationCustom0"
                                  type="text"
                                  v-model="phoneNumber"
                                  name="Phone Number"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider>
                          <!-- <ValidationProvider
                            vid="password"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="form-group row">
                              <label
                                for="validationCustom0"
                                class="col-xl-3 col-md-4"
                                ><span>*</span> Password</label
                              >
                              <div class="col-xl-8 col-md-7">
                                <input
                                  class="form-control"
                                  id="validationCustom0"
                                  type="text"
                                  v-model="password"
                                  name="Password"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider> -->
                          <!-- <ValidationProvider
                            rules="required|confirmed:password"
                            v-slot="{ errors }"
                          >
                            <div class="form-group row">
                              <label
                                for="validationCustom0"
                                class="col-xl-3 col-md-4"
                                ><span>*</span> Confirm Password</label
                              >
                              <div class="col-xl-8 col-md-7">
                                <input
                                  class="form-control"
                                  id="validationCustom0"
                                  type="text"
                                  v-model="confirmPassword"
                                  name="Confirm Password"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </div>
                          </ValidationProvider> -->
                        </form>
                      </ValidationObserver>
                    </b-tab>
                    <!-- <b-tab title="Permission" data-feather="user">
                      <form class="needs-validation user-add" novalidate="">
                        <div class="permission-block">
                          <div class="attribute-blocks">
                            <h5 class="f-w-600 mb-3">
                              Product Related Permission
                            </h5>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Add Product</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani1">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani1"
                                      type="radio"
                                      name="rdo-ani"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani2">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani2"
                                      type="radio"
                                      name="rdo-ani"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Update Product</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani3">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani3"
                                      type="radio"
                                      name="rdo-ani1"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani4">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani4"
                                      type="radio"
                                      name="rdo-ani1"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Delete Product</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani5">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani5"
                                      type="radio"
                                      name="rdo-ani2"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani6">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani6"
                                      type="radio"
                                      name="rdo-ani2"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label class="mb-0 sm-label-radio"
                                  >Apply discount</label
                                >
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0"
                                >
                                  <label class="d-block mb-0" for="edo-ani7">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani7"
                                      type="radio"
                                      name="rdo-ani3"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block mb-0" for="edo-ani8">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani8"
                                      type="radio"
                                      name="rdo-ani3"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="attribute-blocks">
                            <h5 class="f-w-600 mb-3">
                              Category Related Permission
                            </h5>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Add Category</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani9">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani9"
                                      type="radio"
                                      name="rdo-ani4"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani10">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani10"
                                      type="radio"
                                      name="rdo-ani4"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Update Category</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani11">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani11"
                                      type="radio"
                                      name="rdo-ani5"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani12">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani12"
                                      type="radio"
                                      name="rdo-ani5"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Delete Category</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani13">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani13"
                                      type="radio"
                                      name="rdo-ani6"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani14">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani14"
                                      type="radio"
                                      name="rdo-ani6"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label class="mb-0 sm-label-radio"
                                  >Apply discount</label
                                >
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0"
                                >
                                  <label class="d-block mb-0" for="edo-ani15">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani15"
                                      type="radio"
                                      name="rdo-ani7"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block mb-0" for="edo-ani16">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani16"
                                      type="radio"
                                      name="rdo-ani7"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-tab> -->
                  </b-tabs>
                </div>
              </ul>
              <div class="pull-right">
                <button
                  type="button"
                  @click="submitForm"
                  class="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { extend } from 'vee-validate'
extend('phone', {
  validate(value) {
    return /^(\d{11})$/.test(value)
  },
  message: 'Phone number must be 11 digits.'
})
export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate().then(async (isValid) => {
        if (isValid) {
          const userData = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone_number: this.phoneNumber
          }
          const result = await this.$store.dispatch('user/createUser', userData)
          if (result.status === 200) this.$router.push('/users/user-list')
        }
      })
    }
  }
}
</script>
<style scoped></style>
