<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Product List" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Product Lists</h5>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue">
                <b-modal id="modal-2" title="Confirmation" @ok="deleteItem">
                  <p class="my-4">Are you sure!</p>
                </b-modal>
                <b-table
                  striped
                  class="text-center"
                  bordered
                  head-variant="light"
                  show-empty
                  stacked="md"
                  :items="items?.data"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                  :select-mode="selectMode"
                >
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="edit != item.id">{{ value }}</template>
                    <b-form-input type="text" v-else v-model="item[key]" />
                  </template>

                  <template v-slot:cell(actions)="{ item }">
                    <feather
                      type="eye"
                      stroke="#3758FD"
                      stroke-width="1"
                      size="18px"
                      fill="#3758FD"
                      @click="rowSelected(item.id)"
                      stroke-linejoin="round"
                    >
                    </feather>
                    <feather
                      type="edit-2"
                      stroke="#3758FD"
                      stroke-width="1"
                      size="18px"
                      fill="#3758FD"
                      @click="onEdit(item.id)"
                      stroke-linejoin="round"
                    >
                    </feather>
                    <feather
                      type="trash"
                      v-b-modal.modal-2
                      @click="selectedItem = item"
                      stroke="#F72E9F"
                      size="18px"
                      fill="#F72E9F"
                    >
                    </feather>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="items?.totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tablefields: [
        { key: 'id', label: 'Id', sortable: false },
        { key: 'name', label: 'Product Title', sortable: false },
        { key: 'price', label: 'Price', sortable: false },
        { key: 'code', label: 'Product Code', sortable: false },
        { key: 'actions' }
      ],

      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: '',
      selectedItem: {},
      index: null,
      selectMode: 'single'
    }
  },
  created() {
    this.$store.dispatch('product/getProducts', {
      page: this.currentPage,
      limit: this.perPage
    })
  },
  computed: {
    ...mapGetters({
      items: 'product/getProducts'
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    }
  },
  watch: {
    currentPage(newPage) {
      this.$store.dispatch('product/getProducts', {
        page: newPage,
        limit: this.perPage
      })
    }
  },
  methods: {
    async deleteItem() {
      const result = await this.$store.dispatch(
        'product/deleteProduct',
        this.selectedItem.id
      )
      this.selectedItem = {}
      if (result.status === 200) this.$store.dispatch('product/getProducts')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowSelected(id) {
      this.$router.push(`/digital/product-detail/${id}`)
    },
    onEdit(id) {
      this.$router.push(`/digital/edit-product/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
