import EventEmitter from 'events'
const userlogin = 'islogged'
const loginExpiryKey = 'tokenExpiry'
const Userinfo = 'userinfo'
const localStorageKey = 'loggedIn'
import api from '../boot/api'
class Auth extends EventEmitter {
  authToken = null
  userProfile = null
  tokenExpiry = null
  localLogin(authResult) {
    this.tokenExpiry = new Date()
    localStorage.setItem(loginExpiryKey, this.tokenExpiry)
    localStorage.setItem(userlogin, 'true')
    localStorage.setItem(localStorageKey, 'true')
    localStorage.setItem(
      Userinfo,
      JSON.stringify({
        displayName: authResult.user.first_name,
        email: authResult.user.email
      })
    )
  }
  Logout() {
    localStorage.clear()
  }

  async checkAuthentication(to, next) {
    try {
      const result = await api().get('authenticate-admin')
      if (result.status === 200) next()
    } catch {
      next('/auth/login')
    }
  }

  isAuthenticated() {
    return (
      new Date(Date.now()) != new Date(localStorage.getItem(loginExpiryKey)) &&
      localStorage.getItem(userlogin) === 'true'
    )
  }
  isAuthenticatedUser() {
    return (
      new Date(Date.now()) < new Date(localStorage.getItem(loginExpiryKey)) &&
      localStorage.getItem(localStorageKey) === 'true'
    )
  }
}

export default new Auth()
