<template>
  <form
    class="form-horizontal auth-form"
    @submit.prevent="handleSubmit"
    method="post"
  >
    <div class="form-group">
      <input
        v-model="email"
        name="login[email]"
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        placeholder="Email"
      />
    </div>
    <div class="form-group">
      <input
        :type="type"
        v-model="password"
        name="login[password]"
        class="form-control"
        placeholder="Password"
      />
    </div>
    <div class="form-button">
      <button class="btn btn-primary" type="submit" @click="login">
        Login
      </button>
    </div>
  </form>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      type: 'password',
      email: '',
      password: '',
      submitted: false
    }
  },
  created() {
    // reset login status for JWT
    this.$store.dispatch('authentication/logout')
  },
  methods: {
    handleSubmit() {
      this.submitted = true
    },
    login() {
      this.submitted = true
      if (this.email && this.password) {
        let data = {
          email: this.email,
          password: this.password
        }

        this.$store.dispatch('authentication/login', data)
      }
    }
  }
}
</script>
