<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Physical" title="Product Detail" />
      </div>
      <b-modal id="modal-2" title="Confirmation" @ok="deleteItem">
        <p class="my-4">Are you sure!</p>
      </b-modal>
      <div class="card">
        <div class="row product-page-main card-body">
          <div class="col-xl-4">
            <div class="product-slider owl-carousel owl-theme" id="sync1">
              <VueSlickCarousel
                v-if="getDetail.mediaInfo && getDetail.mediaInfo.length"
                v-bind="settings"
                ref="c1"
                :asNavFor="$refs.c2"
                :focusOnSelect="true"
              >
                <div
                  class="item"
                  v-for="(item, index) in getDetail?.mediaInfo?.filter(
                    (item) => item.type === 'image'
                  )"
                  :key="index"
                >
                  <img :src="item.url" alt="" class="blur-up lazyloaded" />
                </div>
              </VueSlickCarousel>
            </div>
            <br />
            <div class="owl-carousel owl-theme" id="sync2">
              <VueSlickCarousel
                v-if="getDetail.mediaInfo && getDetail.mediaInfo.length"
                ref="c2"
                :asNavFor="$refs.c1"
                v-bind="settings1"
                :slidesToShow="3"
                :focusOnSelect="true"
                :infinite="true"
              >
                <div
                  class="item"
                  v-for="(item, index) in getDetail?.mediaInfo?.filter(
                    (item) => item.type === 'image'
                  )"
                  :key="index"
                >
                  <img :src="item.url" alt="" class="blur-up lazyloaded" />
                </div>
              </VueSlickCarousel>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="product-page-details product-right mb-0">
              <h2>{{ getDetail.name }}</h2>
              <hr />
              <h6 class="product-title">product details</h6>
              <p>
                {{ getDetail.description }}
              </p>
              <hr />
              <h6 class="product-title mt-2">Category</h6>
              <p>
                {{ getDetail?.categoryInfo?.name }}
              </p>
              <hr />
              <h6 class="product-title mt-2">Sub Category</h6>
              <p>
                {{ getDetail?.subCategoryInfo?.name }}
              </p>
              <hr />
              <h6 class="product-title mt-2">Type</h6>
              <p>
                {{ getDetail?.subTypeInfo?.name }}
              </p>
              <hr />
              <h6 class="product-title mt-2">Vendor</h6>
              <p>
                {{ getDetail?.vendorInfo?.name }}
              </p>
              <hr />
              <h6 class="product-title mt-2">Price</h6>
              <h4>{{ getDetail?.price }} £</h4>
              <hr />
              <hr />
              <h6 class="product-title mt-2">Asset</h6>
              <h4>
                {{
                  getDetail?.mediaInfo?.find((media) => media.type === 'asset')
                    ?.name
                }}
                <button
                  class="btn"
                  type="button"
                  style="background-color: green"
                  @click="downloadAsset"
                >
                  Download
                </button>
              </h4>
              <hr />
              <div class="m-t-15">
                <button class="btn btn-secondary" type="button">
                  Go Public
                </button>
                <button
                  class="btn ml-2"
                  type="button"
                  style="background-color: #3758fd"
                  @click="editItem"
                >
                  Edit
                </button>
                <button
                  class="btn ml-2"
                  type="button"
                  style="background-color: #f72e9f"
                  v-b-modal.modal-2
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      size: ['S', 'M', 'L', 'XL'],
      selectedSize: '',
      activeColor: '',
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      counter: 1,
      settings: {
        dots: false,
        fade: true,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      settings1: {
        autoplay: false,
        edgeFriction: 0.35,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 0
      }
    }
  },
  created() {
    this.$store.dispatch('product/getProductById', this.itemId)
  },
  computed: {
    getDetail: function () {
      return this.$store.getters['product/getProductById']
    },
    itemId() {
      return this.$route.params.id
    }
  },
  methods: {
    editItem() {
      this.$router.push(`/digital/edit-product/${this.getDetail.id}`)
    },
    async deleteItem() {
      const result = await this.$store.dispatch(
        'product/deleteProduct',
        this.getDetail.id
      )
      if (result.status === 200) this.$router.push(`/digital/product-list`)
    },
    downloadAsset() {
      window.location.href = this.getDetail?.mediaInfo?.find(
        (media) => media.type === 'asset'
      )?.url
    }
  }
}
</script>
