<template>
  <b-modal
    v-model="showModal"
    title="Featured Slide"
    @ok="onSave($event)"
    @cancel="onCancel($event)"
    ok-title="Save"
    ok-variant="primary"
  >
    <ValidationObserver ref="form">
      <form class="needs-validation">
        <div class="form">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Title:</label>
              <div class="d-flex align-items-center">
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  name="Title"
                  v-model="item.title"
                  :style="{ color: item.title_color }"
                />
                <input
                  class="form-control"
                  id="titleColorPicker"
                  type="color"
                  v-model="item.title_color"
                  style="width: 60px; margin-left: 10px"
                />
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Sub Title:</label>
              <div class="d-flex align-items-center">
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  name="Sub Title"
                  v-model="item.sub_title"
                  :style="{ color: item.sub_title_color }"
                />
                <input
                  class="form-control"
                  id="subTitleColorPicker"
                  type="color"
                  v-model="item.sub_title_color"
                  style="width: 60px; margin-left: 10px"
                />
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1"
                >* Action Text:</label
              >
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Action Text"
                v-model="item.action_text"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Action Url:</label>
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Action Url"
                v-model="item.action_url"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="form-group mb-0">
            <label for="validationCustom02" class="mb-1"> Slide Image: </label>
            <input
              class="form-control"
              id="validationCustom02"
              type="file"
              v-on:change="onFileSelected"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  name: 'GlobalSlideModal',
  props: ['showModal', 'selectedItem', 'isEdit'],
  data() {
    return {
      image: '',
      item: {}
    }
  },
  watch: {
    selectedItem(newValue) {
      this.item = { ...newValue }
    }
  },
  methods: {
    resetData() {
      this.image = ''
      this.item = { ...this.selectedItem }
    },
    onCancel(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetData()
      this.$emit('onCancel')
    },
    onFileSelected(event) {
      this.image = event.target.files[0]
    },
    onSave(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          var formData = {
            id: this.item.id,
            title: this.item.title,
            sub_title: this.item.sub_title,
            action_text: this.item.action_text,
            action_url: this.item.action_url,
            title_color: this.item.title_color || '#000000',
            sub_title_color: this.item.sub_title_color || '#000000',
            ...(this.image && {
              file: this.image
            })
          }
          this.isEdit
            ? this.$emit('onEdit', formData)
            : this.$emit('formData', formData)
          this.image = ''
          this.item = {}
        }
      })
    }
  }
}
</script>

<style></style>
