import router from '../../router/index.js'
import api from '../../boot/api'
import Userauth from '../../auth/index.js'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

export const authentication = {
  namespaced: true,
  state: initialState,
  getters: {},
  actions: {
    login({ dispatch, commit }, data) {
      commit('loginRequest', data.email)
      api()
        .post('/sessions', data)
        .then(
          (result) => {
            commit('loginSuccess', user)
            Userauth.localLogin(result.data)
            if (result.data.token) {
              localStorage.setItem('token', result.data.token)
            }
            const { roleName } = result?.data?.user
            if (roleName === 'super-admin') router.push('/')
            else router.push('/vendors/summary')
          },
          (err) => {
            dispatch('alert/error', err, { root: true })
          }
        )
    },

    logout({ commit }) {
      localStorage.removeItem('user')
      commit('logout')
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true }
      state.user = user
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    loginFailure(state) {
      state.status = {}
      state.user = null
    },
    logout(state) {
      state.status = {}
      state.user = null
    }
  }
}
