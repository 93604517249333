<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Users" title="User Lists" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>User Detail</h5>
            </div>
            <div class="card-body">
              <router-link to="/users/create-user">
                <b-button class="btn-popup pull-right"
                  >Create User</b-button
                ></router-link
              >
              <div class="table-responsive datatable-vue user-list">
                <div>
                  <b-modal id="modal-2" title="Confirmation" @ok="deleteItem">
                    <p class="my-4">Are you sure!</p>
                  </b-modal>
                </div>
                <div></div>
                <b-table
                  class="text-center"
                  :select-mode="selectMode"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="items?.data"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #cell(actions)="{ item }">
                    <feather
                      type="edit-2"
                      stroke="#3758FD"
                      stroke-width="1"
                      size="18px"
                      fill="#3758FD"
                      stroke-linejoin="round"
                      @click="
                        showModal = true
                        selectedItem = item
                      "
                    >
                    </feather>
                    <feather
                      type="trash"
                      v-b-modal.modal-2
                      @click="selectedItem = item"
                      stroke="#F72E9F"
                      size="18px"
                      fill="#F72E9F"
                    >
                    </feather>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="items?.totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
      <GlobalUserModal
        @onCancel="onCancel"
        @onEdit="onEdit"
        :showModal="showModal"
        :selectedItem="selectedItem"
        title="Edit User"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GlobalUserModal from '../../components/modals/globalUserModal.vue'
export default {
  components: {
    GlobalUserModal
  },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      tablefields: [
        { key: 'first_name', label: 'First Name', sortable: false },
        { key: 'last_name', label: 'Last Name', sortable: false },
        { key: 'email', label: 'Email', sortable: false },
        { key: 'created_at', label: 'Created', sortable: false },
        { key: 'updated_at', label: 'Updated', sortable: false },
        { key: 'actions' }
      ],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selectMode: 'multi',
      selected: [],
      showModal: false,
      selectedItem: {}
    }
  },
  created() {
    this.$store.dispatch('user/getUsers', {
      page: this.currentPage,
      limit: this.perPage
    })
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  computed: {
    ...mapGetters({
      items: 'user/getUsers'
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    }
  },
  watch: {
    currentPage(newPage) {
      this.$store.dispatch('user/getUsers', {
        page: newPage,
        limit: this.perPage
      })
    }
  },
  methods: {
    onCancel() {
      this.showModal = false
    },
    async onEdit(userData) {
      const result = await this.$store.dispatch('user/updateUser', userData)
      if (result.status === 200) {
        this.$store.dispatch('user/getUsers', {
          page: this.currentPage,
          limit: this.perPage
        })
        this.showModal = false
      }
    },
    async deleteItem() {
      const result = await this.$store.dispatch(
        'user/deleteUser',
        this.selectedItem.id
      )
      this.selectedItem = {}
      if (result.status === 200) {
        this.$store.dispatch('user/getUsers', {
          page: this.currentPage,
          limit: this.perPage
        })
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
