<template>
  <div>
    <div>
      <div
        v-for="(image, index) in images"
        :key="index"
        class="photo-container"
      >
        <img :src="image.url" alt="Photo" />
        <button
          @click="
            showModal = true
            selectedId = image.id
          "
        >
          Delete
        </button>
      </div>
      <div style="margin: 10px; padding: 5px">
        <h4>
          <a :href="asset?.url" target="_blank" rel="noopener noreferrer">
            {{ asset?.name }}
          </a>
        </h4>
      </div>
      <div class="card">
        <div class="card-header">
          <h5>General</h5>
        </div>
        <div class="card-body">
          <ValidationObserver ref="form">
            <form>
              <div class="digital-add needs-validation">
                <div class="form-group">
                  <label class="col-form-label pt-0"> Product Upload</label>
                  <div>
                    <vue-dropzone
                      id="my-dropzone"
                      ref="myVueDropzone"
                      :options="dropzoneOptions"
                    />
                  </div>
                  <!-- <span
                    class="block text-danger text-xs absolute bottom-0 left-0"
                    >{{ errors[0] }}</span
                  > -->
                  <!-- </ValidationProvider> -->
                </div>
              </div>
            </form>
          </ValidationObserver>
          <!-- form end working -->
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" title="Confirmation" @ok="deleteImage">
      <p class="my-4">Are you sure!</p>
    </b-modal>
    <div class="pull-right">
      <button
        type="button"
        @click="uploadFiles"
        class="btn btn-primary"
        :disabled="loading || (type === '360' && !isEnoughImagesUploaded())"
      >
        {{ loading ? 'Uploading...' : 'Upload Files' }}
      </button>
    </div>
    <!-- Container-fluid Ends-->
    <button
      v-if="type === '360' && !isEdit"
      type="button"
      @click="
        () => {
          this.$emit('activateTab', 5, id)
        }
      "
      class="btn btn-primary pl-20"
    >
      Next
    </button>
  </div>
</template>

<script>
export default {
  name: 'UploadMedia',
  props: ['type', 'images', 'id', 'isEdit', 'asset'],
  data() {
    return {
      dropzoneOptions: {
        url: 'localhost:8080',
        autoProcessQueue: false,
        ...(this.type === 'asset' ? { maxFiles: 1 } : {})
      },
      showModal: false,
      selectedId: '',
      loading: false,
      fileCount: 0
    }
  },
  mounted() {
    this.$refs.myVueDropzone.dropzone.on('addedfile', this.fileAdded)
    this.$refs.myVueDropzone.dropzone.on('removedfile', this.fileRemoved)
  },

  beforeDestroy() {
    this.$refs.myVueDropzone.dropzone.off('addedfile', this.fileAdded)
    this.$refs.myVueDropzone.dropzone.off('removedfile', this.fileRemoved)
  },

  methods: {
    async uploadFiles() {
      this.loading = true
      var myDropzone = this.$refs.myVueDropzone.dropzone
      var formData = new FormData()
      myDropzone.files.forEach(function (file) {
        formData.append('file', file)
      })
      formData.append('product_id', this.id)
      formData.append(
        'type',
        this.type === 'asset'
          ? 'asset'
          : this.type === 'images'
          ? 'image'
          : '360'
      )
      const res = await this.$store.dispatch(
        'product/createProductMedia',
        formData
      )
      this.loading = false
      this.type === 'images' && res.status === 200
        ? this.$emit('activateTab', 3, this.id)
        : this.type === '360' && res.status === 200
        ? this.$emit('activateTab', 5, this.id)
        : this.$router.push('/digital/product-list')
    },
    deleteImage() {
      this.$emit('deleteImage', this.selectedId)
      this.showModal = false
      this.selectedId = ''
    },
    fileAdded() {
      this.fileCount++
    },

    fileRemoved() {
      this.fileCount--
    },

    isEnoughImagesUploaded() {
      return this.type !== '360' || (this.type === '360' && this.fileCount > 1)
    }
  }
}
</script>
<style scoped>
.photo-container {
  display: inline-block;
  position: relative;
  margin: 10px;
  border: 1px solid #ccc;
  padding: 5px;
}

.photo-container img {
  width: 200px;
  height: 200px;
  max-width: 100%;
  max-height: 100%;
}

.photo-container button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
.ck-content {
  height: 500px;
}
</style>
