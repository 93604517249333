<template>
  <b-modal
    ref="categoryModal"
    v-model="showModal"
    :title="
      type === 'category'
        ? 'Category'
        : type === 'subCategory'
        ? 'Sub Category'
        : 'Sub Type'
    "
    @ok="onSave($event)"
    @cancel="onCancel($event)"
    ok-title="Save"
    ok-variant="primary"
  >
    <ValidationObserver ref="form">
      <form class="needs-validation">
        <div class="form">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1"
                >*
                {{
                  type === 'category'
                    ? 'Category Name'
                    : type === 'subCategory'
                    ? 'Sub Category Name'
                    : 'Sub Type Name'
                }}
                :</label
              >
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                name="Name"
                v-model="item.name"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="form-group mb-0">
            <label for="validationCustom02" class="mb-1">
              {{
                type === 'category'
                  ? 'Category'
                  : type === 'subCategory'
                  ? 'Sub Category'
                  : 'Sub Type'
              }}
              Image :
            </label>
            <input
              class="form-control"
              id="validationCustom02"
              type="file"
              v-on:change="onFileSelected"
            />
          </div>
          <ValidationProvider
            v-if="type !== 'category'"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group mb-0">
              <label for="validationCustom02" class="mb-1">Category:</label>
              <select
                class="form-control"
                name="Category"
                v-model="item.categoryId"
                @change="getSubcategories"
              >
                <option value="">--Select--</option>
                <option
                  v-for="option in categories.data"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="form-group" v-if="type === 'subType'">
            <label class="col-form-label"><span>*</span> Sub Category</label>
            <ValidationProvider
              name="Sub Category"
              rules="required"
              v-slot="{ errors }"
            >
              <select
                class="custom-select"
                required=""
                v-model="item.subCategoryId"
                :disabled="!item.categoryId"
              >
                <option value="">--Select--</option>
                <option
                  v-for="option in subCategories.data"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
              <span
                class="block text-danger text-xs absolute bottom-0 left-0"
                >{{ errors[0] }}</span
              >
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GlobalCategoryModal',
  props: ['type', 'showModal', 'selectedItem', 'isEdit'],
  data() {
    return {
      image: '',
      item: {}
    }
  },
  watch: {
    selectedItem(newValue) {
      if (this.isEdit) {
        if (this.type === 'category') {
          this.item = { ...newValue }
        }
        if (this.type === 'subCategory') {
          const {
            categoryInfo: { id: categoryId },
            ...rest
          } = newValue
          const updatedData = {
            ...rest,
            categoryId
          }
          this.item = { ...updatedData }
        }
        if (this.type === 'subType') {
          const {
            subCategoryInfo: {
              id: subCategoryId,
              categoryInfo: { id: categoryId }
            },
            ...rest
          } = newValue
          const updatedData = {
            ...rest,
            subCategoryId,
            categoryId
          }
          this.getSubcategories(categoryId)
          this.item = { ...updatedData }
        }
      } else {
        this.item = {}
      }
    }
  },
  created() {
    if (this.type !== 'category')
      this.$store.dispatch('product/getCategoryProduct')
  },
  computed: {
    ...mapGetters({
      categories: 'product/getCategoryProduct',
      subCategories: 'product/getSubCategoryProduct'
    })
  },
  methods: {
    resetData() {
      this.image = ''
      if (this.isEdit) {
        if (this.type === 'category') {
          this.item = { ...this.selectedItem }
        }
        if (this.type === 'subCategory') {
          const {
            categoryInfo: { id: categoryId },
            ...rest
          } = this.selectedItem
          const updatedData = {
            ...rest,
            categoryId
          }
          this.item = { ...updatedData }
        }
        if (this.type === 'subType') {
          const {
            subCategoryInfo: {
              id: subCategoryId,
              categoryInfo: { id: categoryId }
            },
            ...rest
          } = this.selectedItem
          const updatedData = {
            ...rest,
            subCategoryId,
            categoryId
          }
          this.item = { ...updatedData }
        }
      } else {
        this.item = {}
      }
    },
    onCancel(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetData()
      this.$emit('onCancel')
    },
    onFileSelected(event) {
      this.image = event.target.files[0]
    },
    getSubcategories() {
      if (this.type !== 'subCategory') {
        this.$store.dispatch('product/getSubCategoryProduct', {
          category_id: this.item.categoryId
        })
      }
    },
    onSave(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          var formData = {
            id: this.item.id,
            name: this.item.name,
            ...(this.image && {
              file: this.image
            }),
            ...(this.type !== 'category' && {
              category_id: this.item.categoryId
            }),
            ...(this.type === 'subType' && {
              sub_category_id: this.item.subCategoryId
            })
          }
          this.isEdit
            ? this.$emit('onEdit', formData)
            : this.$emit('formData', formData)
          this.image = ''
          this.item = {}
        }
      })
    }
  }
}
</script>

<style></style>
