<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ items?.data?.[0]?.vendorName }}</h5>
            </div>
            <div class="card-body">
              <b-row>
                <b-col xl="3" lg="4" md="6">
                  <b-form-group
                    label-cols="2"
                    label="show"
                    class="datatable-select"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col
                  class="offset-xl-6 offset-lg-2 search-rs"
                  xl="3"
                  lg="5"
                  md="6"
                >
                  <b-form-group
                    label-cols="3"
                    label="search:"
                    class="datatable-select"
                  >
                    <b-form-input
                      type="text"
                      v-model="filter"
                      placeholder="Search"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="table-responsive datatable-vue">
                <b-table
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="items.data"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="items?.totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                >
                </b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      tablefields: [
        { key: 'name', label: 'Product Name', sortable: false },
        { key: 'purchasecount', label: 'No of purchases', sortable: false }
      ],

      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selectMode: 'multi',
      showModal: false,
      selectedItem: {}
    }
  },
  created() {
    this.$store.dispatch('vendor/getVendorSummary', {
      page: this.currentPage,
      limit: this.perPage
    })
  },

  computed: {
    ...mapGetters({
      items: 'vendor/getVendorSummary'
    })
  },
  watch: {
    currentPage(newPage) {
      this.$store.dispatch('vendor/getVendorSummary', {
        page: newPage,
        limit: this.perPage
      })
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
