import api from '../../boot/api'
const url = 'featured-slides'
import { showToast } from '../../utilities/utilities'

const state = {
  featuredSlides: []
}
const getters = {
  getFeaturedSlides(state) {
    return state.featuredSlides
  }
}
const actions = {
  getFeaturedSlides(context, query) {
    api()
      .get(url, { params: query })
      .then((response) => {
        context.commit('getFeaturedSlides', {
          data: response.data.results,
          totalRows: response.data.total
        })
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  createFeaturedSlide(context, payload) {
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('title', payload.title)
    bodyFormData.append('sub_title', payload.sub_title)
    bodyFormData.append('action_url', payload.action_url)
    bodyFormData.append('action_text', payload.action_text)
    bodyFormData.append('title_color', payload.title_color)
    bodyFormData.append('sub_title_color', payload.sub_title_color)
    return api()
      .post(url, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Slide Created Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  updateFeaturedSlide(context, { id, created_at, updated_at, ...payload }) {
    var bodyFormData = new FormData()
    payload.file && bodyFormData.append('file', payload.file)
    bodyFormData.append('title', payload.title)
    bodyFormData.append('sub_title', payload.sub_title)
    bodyFormData.append('action_url', payload.action_url)
    bodyFormData.append('action_text', payload.action_text)
    bodyFormData.append('title_color', payload.title_color)
    bodyFormData.append('sub_title_color', payload.sub_title_color)
    return api()
      .put(url + `/${id}`, bodyFormData)
      .then(
        (response) => {
          showToast(context, 'Slide Updated Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  },
  deleteFeaturedSlide(context, id) {
    return api()
      .delete(url + `/${id}`)
      .then(
        (response) => {
          showToast(context, 'Slide Deleted Successfully.')
          return response
        },
        (err) => {
          console.log('error', err)
        }
      )
  }
}
const mutations = {
  getFeaturedSlides(state, payload) {
    state.featuredSlides = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
