<template>
  <div id="app">
    <GlobalToast />
    <router-view />
  </div>
</template>
<script>
import GlobalToast from './components/toast/globalToast.vue'
export default {
  components: {
    GlobalToast
  }
}
</script>
<style></style>
