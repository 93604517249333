<template>
  <b-modal
    v-model="showModal"
    title="Product Material"
    @ok="onSave($event)"
    @cancel="onCancel($event)"
    ok-title="Save"
    ok-variant="primary"
  >
    <ValidationObserver ref="form">
      <form class="needs-validation">
        <div class="form">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">* Name:</label>
              <div class="d-flex align-items-center">
                <input
                  class="form-control"
                  id="validationCustom01"
                  type="text"
                  name="Name"
                  v-model="item.name"
                />
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  name: 'GlobalMaterialModal',
  props: ['showModal', 'selectedItem', 'isEdit'],
  data() {
    return {
      image: '',
      item: {}
    }
  },
  watch: {
    selectedItem(newValue) {
      this.item = { ...newValue }
    }
  },
  methods: {
    resetData() {
      this.item = { ...this.selectedItem }
    },
    onCancel(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetData()
      this.$emit('onCancel')
    },
    onSave(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          var formData = {
            id: this.item.id,
            name: this.item.name
          }
          this.isEdit
            ? this.$emit('onEdit', formData)
            : this.$emit('formData', formData)
          this.item = {}
        }
      })
    }
  }
}
</script>

<style></style>
