<template>
  <div>
    <b-container
      v-if="images.filter((image) => image.type === 'image')?.length"
      fluid
    >
      <b-row>
        <b-col
          lg="3"
          md="6"
          sm="12"
          class="mb-4"
          v-for="(image, index) in images.filter(
            (image) => image.type === 'image'
          )"
          :key="index"
        >
          <b-card
            class="custom-card"
            :img-src="image?.url"
            img-alt="Image"
            img-top
          >
            <b-form @submit.prevent="onSubmit(image.id, index)">
              <div class="form">
                <div class="form-group mb-2">
                  <label for="validationCustom02" class="mb-1">Material:</label>
                  <select
                    class="form-control"
                    name="Material"
                    v-model="selectedOptions[index].material"
                  >
                    <option value="">--Select--</option>
                    <option
                      v-for="option in materials"
                      :key="option.id"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group mb-2">
                  <label for="validationCustom02" class="mb-1">Color:</label>
                  <select
                    class="form-control"
                    name="Color"
                    v-model="selectedOptions[index].color"
                  >
                    <option value="">--Select--</option>
                    <option
                      v-for="option in colors"
                      :key="option.id"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <button class="btn btn-primary">Submit</button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <h4 v-else>No images uploaded for this product</h4>
    <div class="pull-right" v-if="!isEdit">
      <button
        type="button"
        @click="
          () => {
            this.$emit('activateTab', 4, id)
          }
        "
        class="btn btn-primary"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkImages',
  props: ['isEdit', 'id'],
  data() {
    return {
      dropdownOptions1: ['Option 1-1', 'Option 1-2', 'Option 1-3'],
      dropdownOptions2: ['Option 2-1', 'Option 2-2', 'Option 2-3'],
      selectedOptions: [],
      images: [],
      materials: [],
      colors: []
    }
  },
  created() {
    this.$store.dispatch('product/getProductById', this.id)
  },

  computed: {
    product() {
      return this.$store.getters['product/getProductById']
    }
  },
  watch: {
    product: {
      handler(newVal) {
        this.selectedOptions = newVal?.mediaInfo.map((media) => ({
          material: media?.material_id ? media?.material_id : '',
          color: media?.color_id ? media?.color_id : ''
        }))
        this.images = newVal?.mediaInfo
        this.materials = newVal?.materialsInfo.map((obj) => ({
          id: obj.material_id,
          name: obj.materialInfo.name
        }))
        this.colors = newVal?.colors
      }
    }
  },
  methods: {
    onSubmit(id, index) {
      const payload = {
        id,
        material_id: this.selectedOptions[index].material,
        color_id: this.selectedOptions[index].color
      }
      this.$store.dispatch('product/updateProductMedia', payload)
    }
  }
}
</script>
<style scoped>
.custom-card {
  max-width: 100%;
}
</style>
