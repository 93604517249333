<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Add Products" />
      </div>
      <div class="row product-adding col-xl-12">
        <ul class="nav nav-tabs tab-coupon" id="top-tab" role="tablist">
          <b-tabs content-class="mt-3">
            <b-tab
              title="Info"
              data-feather="product"
              active
              style="display: flex"
              :disabled="activeTab !== 1"
              :active="activeTab === 1"
            >
              <GeneralInfo @activateTab="activateTab" />
            </b-tab>
            <b-tab
              title="Images"
              data-feather="product"
              style="display: flex"
              :disabled="activeTab !== 2"
              :active="activeTab === 2"
            >
              <uploadMedia
                v-if="activeTab === 2"
                @activateTab="activateTab"
                :id="productId"
                type="images"
              />
            </b-tab>
            <b-tab
              title="Link Images"
              data-feather="product"
              style="display: flex"
              :disabled="activeTab !== 3"
              :active="activeTab === 3"
            >
              <LinkImage
                v-if="activeTab === 3"
                @activateTab="activateTab"
                :id="productId"
              />
            </b-tab>
            <b-tab
              title="360 Image"
              data-feather="product"
              style="display: flex"
              :disabled="activeTab !== 4"
              :active="activeTab === 4"
            >
              <UploadMedia
                v-if="activeTab === 4"
                @activateTab="activateTab"
                type="360"
                :id="productId"
              />
            </b-tab>
            <b-tab
              title="Asset"
              data-feather="product"
              style="display: flex"
              :disabled="activeTab !== 5"
              :active="activeTab === 5"
            >
              <UploadMedia
                v-if="activeTab === 5"
                @activateTab="activateTab"
                type="asset"
                :id="productId"
              />
            </b-tab>
          </b-tabs>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralInfo from '../../components/addproduct/general-info.vue'
import UploadMedia from '../../components/addproduct/upload-media.vue'
import LinkImage from '../../components/addproduct/link-images.vue'
export default {
  components: {
    GeneralInfo,
    UploadMedia,
    LinkImage
  },
  data() {
    return {
      activeTab: 1,
      productId: ''
    }
  },
  methods: {
    activateTab(index, productId) {
      this.activeTab = index
      this.productId = productId
    }
  }
}
</script>
<style scoped>
.ck-content {
  height: 500px;
}
</style>
