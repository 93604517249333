<template>
  <div class="page-wrapper">
    <div class="authentication-box">
      <div class="container">
        <div class="row m-lg-0">
          <div class="col-md-5 p-0 card-left">
            <div class="card bg-primary">
              <div class="svg-icon">
                <img
                  width="100%"
                  class="blur-up lazyloaded"
                  src="newlogo.png"
                  alt=""
                />
              </div>
              <div class="single-item">
                <div>
                  <h3>Welcome to Modelii</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 p-0 card-right">
            <div class="card tab2-card">
              <div class="card-body">
                <login></login>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import login from '../authentication/login.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: { login, swiper, swiperSlide },
  data() {
    return {
      type: 'password',
      password: '',
      username: '',
      submitted: false
    }
  }
}
</script>
