<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Vendors" title="Vendor Lists" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Vendor Detail</h5>
              <router-link to="/vendors/create-vendor">
                <b-button class="btn-popup pull-right"
                  >Create Vendor</b-button
                ></router-link
              >
            </div>
            <div class="card-body">
              <b-row>
                <b-col xl="3" lg="4" md="6">
                  <b-form-group
                    label-cols="2"
                    label="show"
                    class="datatable-select"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col
                  class="offset-xl-6 offset-lg-2 search-rs"
                  xl="3"
                  lg="5"
                  md="6"
                >
                  <b-form-group
                    label-cols="3"
                    label="search:"
                    class="datatable-select"
                  >
                    <b-form-input
                      type="text"
                      v-model="filter"
                      placeholder="Search"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="table-responsive datatable-vue">
                <div>
                  <b-modal id="modal-2" title="Confirmation" @ok="deleteItem">
                    <p class="my-4">Are you sure!</p>
                  </b-modal>
                </div>
                <div>
                  <b-modal
                    id="modal-3"
                    title="Confirmation"
                    @ok="sendVendorInfo"
                  >
                    <p class="my-4">Are you sure!</p>
                  </b-modal>
                </div>
                <b-table
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="items.data"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #cell(actions)="{ item }">
                    <feather
                      type="edit-2"
                      stroke="#3758FD"
                      stroke-width="1"
                      size="18px"
                      fill="#3758FD"
                      stroke-linejoin="round"
                      @click="
                        showModal = true
                        selectedItem = item
                      "
                    >
                    </feather>
                    <feather
                      type="trash"
                      v-b-modal.modal-2
                      @click="selectedItem = item"
                      stroke="#F72E9F"
                      stroke-width="1"
                      stroke-linejoin="round"
                      size="18px"
                      fill="#F72E9F"
                    >
                    </feather>
                    <feather
                      type="mail"
                      stroke-width="1"
                      stroke-linejoin="round"
                      v-b-modal.modal-3
                      @click="selectedItem = item"
                      size="18px"
                    >
                    </feather>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="items?.totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                >
                </b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
      <GlobalUserModal
        @onCancel="onCancel"
        @onEdit="onEdit"
        type="vendor"
        :showModal="showModal"
        :selectedItem="selectedItem"
        title="Edit Vendor"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GlobalUserModal from '../../components/modals/globalUserModal.vue'
export default {
  components: {
    GlobalUserModal
  },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      tablefields: [
        { key: 'name', label: 'Vendor Name', sortable: true },
        { key: 'userInfo.email', label: 'Email', sortable: false },
        { key: 'userInfo.first_name', label: 'First Name', sortable: false },
        { key: 'userInfo.last_name', label: 'Last Name', sortable: false },
        { key: 'userInfo.last_name', label: 'Last Name', sortable: false },
        { key: 'address', label: 'Address', sortable: false },
        { key: 'phone_number', label: 'Phone Number', sortable: true },
        { key: 'created_at', label: 'Created', sortable: false },
        { key: 'updated_at', label: 'Updated', sortable: false },
        { key: 'actions' }
      ],

      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selectMode: 'multi',
      showModal: false,
      selectedItem: {}
    }
  },
  created() {
    this.$store.dispatch('vendor/getVendors', {
      page: this.currentPage,
      limit: this.perPage
    })
  },

  computed: {
    ...mapGetters({
      items: 'vendor/getVendors'
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    }
  },
  watch: {
    currentPage(newPage) {
      this.$store.dispatch('vendor/getVendors', {
        page: newPage,
        limit: this.perPage
      })
    }
  },
  methods: {
    onCancel() {
      this.showModal = false
    },
    async onEdit(userData) {
      const result = await this.$store.dispatch('vendor/updateVendor', userData)
      if (result.status === 200) {
        this.$store.dispatch('vendor/getVendors', {
          page: this.currentPage,
          limit: this.perPage
        })
        this.showModal = false
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async deleteItem() {
      const result = await this.$store.dispatch(
        'vendor/deleteVendor',
        this.selectedItem.id
      )
      this.selectedItem = {}
      if (result.status === 200) {
        this.$store.dispatch('vendor/getVendors', {
          page: this.currentPage,
          limit: this.perPage
        })
      }
    },
    async sendVendorInfo() {
      const result = await this.$store.dispatch(
        'user/sendInfo',
        this.selectedItem.admin_id
      )
      this.selectedItem = {}
      if (result.status === 200) {
        this.$store.dispatch('vendor/getVendors', {
          page: this.currentPage,
          limit: this.perPage
        })
      }
    }
  }
}
</script>
