<template>
  <div style="display: flex">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>General</h5>
        </div>
        <div class="card-body">
          <ValidationObserver ref="form">
            <form>
              <div class="digital-add needs-validation">
                <div class="form-group">
                  <label for="validationCustom01" class="col-form-label pt-0"
                    ><span>*</span> Title</label
                  >
                  <ValidationProvider
                    name="Title"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      class="form-control"
                      id="validationCustom01"
                      type="text"
                      v-model="product.name"
                    />
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="col-form-label"><span>*</span> Category</label>
                  <ValidationProvider
                    name="Category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      class="custom-select"
                      required=""
                      v-model="product.categoryInfo.id"
                      @change="getSubcategories"
                    >
                      <option value="">--Select--</option>
                      <option
                        v-for="option in categories.data"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="col-form-label"
                    ><span>*</span> Sub Category</label
                  >
                  <ValidationProvider
                    name="Sub Category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      class="custom-select"
                      required=""
                      v-model="product.subCategoryInfo.id"
                      @change="getSubTypes"
                      :disabled="!product.categoryInfo.id"
                    >
                      <option value="">--Select--</option>
                      <option
                        v-for="option in subCategories.data"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="col-form-label"><span>*</span> Sub Type</label>
                  <ValidationProvider
                    name="Sub Type"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      class="custom-select"
                      required=""
                      v-model="product.subTypeInfo.id"
                      :disabled="
                        !product.categoryInfo.id || !product.subCategoryInfo.id
                      "
                    >
                      <option value="">--Select--</option>
                      <option
                        v-for="option in subTypes.data"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="col-form-label"><span>*</span> Vendor</label>
                  <ValidationProvider
                    name="Vendor"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      class="custom-select"
                      required=""
                      v-model="product.vendorInfo.id"
                    >
                      <option value="">--Select--</option>
                      <option
                        v-for="option in vendors.data"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="validationCustom02" class="col-form-label"
                    ><span>*</span> Product Price</label
                  >
                  <ValidationProvider
                    name="Price"
                    :rules="{
                      required: true,
                      regex: /^\d+(\.\d+)?$/
                    }"
                    v-slot="{ errors }"
                  >
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      required=""
                      v-model="product.price"
                    />
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="validationCustom02" class="col-form-label"
                    ><span>*</span> Product Code</label
                  >
                  <ValidationProvider
                    name="Product Code"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      class="form-control"
                      id="validationCustom02"
                      type="text"
                      required=""
                      v-model="product.code"
                    />
                    <span
                      class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="my-2">
                  <label class="col-form-label"><span>*</span> Material</label>
                  <multiselect
                    v-if="materials?.data"
                    v-model="computedMaterialsInfo"
                    placeholder="Select Material"
                    label="name"
                    track-by="id"
                    :options="materials.data"
                    :multiple="true"
                  />
                </div>
                <div v-if="computedMaterialsInfo?.length">
                  <div
                    v-for="(color, index) in product?.colors"
                    :key="index"
                    style="display: flex; margin-top: 5px"
                  >
                    <input
                      class="form-control"
                      type="color"
                      v-model="product?.colors[index].name"
                      style="width: 60px; margin-left: 10px"
                    />
                    <button
                      class="btn btn-sm text-danger my-n2"
                      type="button"
                      @click="removeColorPicker(index)"
                    >
                      x
                    </button>
                  </div>
                  <button
                    class="btn btn-primary my-2"
                    type="button"
                    @click="addColorPicker"
                  >
                    Add Color
                  </button>
                </div>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="featured"
                      v-model="product.is_featured"
                    />
                    <label class="form-check-label" for="featured"
                      >Is Featured
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="sale"
                      v-model="product.is_bestseller"
                    />
                    <label class="form-check-label" for="sale">Is Sale</label>
                  </div>
                </div>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="material"
                      v-model="product.material_edit"
                    />
                    <label class="form-check-label" for="material">
                      Material edit
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="dimension"
                      v-model="product.dimension_edit"
                    />
                    <label class="form-check-label" for="dimension"
                      >Dimension edit
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
          <!-- form end working -->
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Add Description</h5>
        </div>
        <div class="card-body">
          <div class="digital-add needs-validation">
            <div class="md-12">
              <vue-editor v-model="product.description"></vue-editor>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h5>Meta Data</h5>
        </div>
        <div class="card-body col-xl-6" style="display: flex">
          <div class="digital-add needs-validation">
            <div class="form-group">
              <label for="validationCustom05" class="col-form-label pt-0">
                Meta Title</label
              >
              <input
                class="form-control"
                id="validationCustom05"
                type="text"
                required=""
                v-model="metaTitle"
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">Meta Description</label>
              <textarea v-model="metaDescription" rows="4" cols="12"></textarea>
            </div>
            <div class="form-group mb-0">
              <div class="product-buttons text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="!metaTitle || !metaDescription"
                  @click="handleMetaData"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <table v-if="Object.keys(metaData).length" class="table mt-3 col-6">
              <thead class="text-center">
                <tr>
                  <th scope="col">Key</th>
                  <th scope="col">Value</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="(value, key) in metaData">
                  <td>{{ key }}</td>
                  <td>{{ value }}</td>
                  <td class="text-justify">
                    <button
                      class="btn btn-sm text-danger my-n2"
                      @click="removeMetadata(key)"
                    >
                      x
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="pull-right">
        <button type="button" @click="submitForm" class="btn btn-primary">
          Save
        </button>
      </div>
    </div>

    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'GeneralInfo',
  components: {
    Multiselect
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          id: null,
          name: '',
          price: '',
          categoryInfo: { id: '' },
          subCategoryInfo: { id: '' },
          subTypeInfo: { id: '' },
          vendorInfo: { id: '' },
          materialsInfo: [],
          colors: [{ name: '#000000' }]
        }
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: '<h1>Some initial content</h1>',
      name: '',
      price: '',
      selectedCategory: '',
      selectedSubCategory: '',
      selectedSubType: '',
      selectedVendor: '',
      metaData: {},
      metaTitle: '',
      metaDescription: '',
      localMaterialsInfo: []
    }
  },

  created() {
    this.$store.dispatch('product/getCategoryProduct')
    this.$store.dispatch('vendor/getVendors')
    this.$store.dispatch('product/getMaterial')
  },
  mounted() {
    if (this.isEdit) {
      this.getSubcategories()
      this.getSubTypes()
    }
  },
  watch: {
    'product.materialsInfo': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && this.isEdit) {
          this.localMaterialsInfo = newValue.map((material) => ({
            id: material.material_id,
            name: material.materialInfo.name
          }))
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      categories: 'product/getCategoryProduct',
      vendors: 'vendor/getVendors',
      subCategories: 'product/getSubCategoryProduct',
      subTypes: 'product/getSubTypeProduct',
      materials: 'product/getMaterial'
    }),

    computedMaterialsInfo: {
      get() {
        return this.localMaterialsInfo
      },
      set(value) {
        this.localMaterialsInfo = value
      }
    }
  },
  methods: {
    getSubcategories() {
      this.$store.dispatch('product/getSubCategoryProduct', {
        category_id: this.product?.categoryInfo?.id
      })
    },
    getSubTypes() {
      this.$store.dispatch('product/getSubTypeProduct', {
        category_id: this.product?.categoryInfo?.id,
        sub_category_id: this.product?.subCategoryInfo?.id
      })
    },
    handleMetaData() {
      this.metaData[this.metaTitle] = this.metaDescription
      this.metaTitle = ''
      this.metaDescription = ''
    },
    removeMetadata(key) {
      delete this.metaData[key]
      this.$set(this, 'metaData', Object.assign({}, this.metaData))
    },
    addColorPicker() {
      this.product.colors.push({ name: '#000000' })
    },
    removeColorPicker(index) {
      this.product.colors.splice(index, 1)
    },
    submitForm() {
      this.$refs.form.validate().then(async (isValid) => {
        if (isValid) {
          const productData = {
            ...(this.isEdit && { id: this.product.id }),
            name: this.product.name,
            category_id: this.product.categoryInfo.id,
            sub_category_id: this.product.subCategoryInfo.id,
            product_type_id: this.product.subTypeInfo.id,
            vendor_id: this.product.vendorInfo.id,
            price: this.product.price,
            description: this.product.description,
            meta: this.metaData,
            is_featured: this.product.is_featured,
            is_bestseller: this.product.is_bestseller,
            material_edit: this.product.material_edit,
            dimension_edit: this.product.dimension_edit,
            code: this.product.code,
            materials: this.computedMaterialsInfo.map(
              (material) => material.id
            ),
            colors: this.computedMaterialsInfo?.length
              ? this.product.colors
              : []
          }
          let response = null
          if (this.isEdit) {
            response = await this.$store.dispatch(
              'product/updateProduct',
              productData
            )
          } else {
            response = await this.$store.dispatch(
              'product/createProduct',
              productData
            )
          }

          if (response.status === 200)
            this.$emit('activateTab', 2, response.data.product.id)
        }
      })
    }
  }
}
</script>

<style scoped>
table.table {
  border: none;
  border-collapse: collapse;
}
table.table th,
table.table td {
  padding: 0.5rem;
  border: none;
}
.ck-content {
  height: 500px;
}
</style>
